import * as React from "react"
import { Link, graphql } from "gatsby"

import { AuthenticatedTemplate } from "@azure/msal-react";

import Layout from "../components/layout"
import Seo from "../components/seo"

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import BugReportIcon from '@mui/icons-material/BugReport';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const BugComp = (props) => {
    return (
        <div style={{ marginTop: "15px" }}>
            <BugReportIcon fontSize="large" style={{ color: "red", verticalAlign: "middle", marginRight: "15px" }} />
            <p style={{ verticalAlign: "bottom", display: "inline" }}>
                { props.bug.CustomTitle ? props.bug.CustomTitle : props.bug.Title }
                <AuthenticatedTemplate>
                    <Link to={props.bug.Id} style={{ float: "right" }}>
                        More Info
                    </Link>
                </AuthenticatedTemplate>
            </p>
        </div>
    )
}

const EnhancementComp = (props) => {
    return (
        <div style={{ marginTop: "15px" }}>
            <MenuBookIcon fontSize="large" style={{ color: "#006dff", verticalAlign: "middle", marginRight: "15px" }} />
            <p style={{ verticalAlign: "bottom", display: "inline" }}>
                { props.enhancement.CustomTitle ? props.enhancement.CustomTitle : props.enhancement.Title }
                <AuthenticatedTemplate>
                    <Link to={props.enhancement.Id} style={{ float: "right" }}>
                    More Info
                    </Link>
                </AuthenticatedTemplate>
            </p>
        </div>
    )
}

const ReleaseNotesTemplate = ({ data, location }) => {
  const releaseNotes = data.releaseNotes
  const parsedbody = JSON.parse(releaseNotes.Body)
  const enhancements = parsedbody.Enhancements
  const bugs = parsedbody.Bugs
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={releaseNotes.Title} />
      <Container maxWidth="md">
          <Box sx={{ my: 4 }}>
              <div>
                <Typography color="inherit" align="center" variant="h2" marked="center" style={{ zIndex: 1 }}>
                    {releaseNotes.Title}
                </Typography>
                <AuthenticatedTemplate>
                    <Fab size="medium" color="secondary" aria-label="add" style={{ marginTop: "-60px" }}>
                        <EditIcon />
                    </Fab>
                </AuthenticatedTemplate>
              </div>
            <Chip label={new Date(releaseNotes.Timestamp).toDateString()} sx={{
                marginLeft: { xs: "28%", sm: "inherit" },
                float: { xs: "inherit", sm: "right" }, 
                marginTop: { xs: "0px", sm: "-50px" }
            }} />
            <Typography color="inherit" align="left" variant="h3" marked="center" style={{ zIndex: 1, fontSize: "2rem" }} sx={{
                marginTop: { xs: "40px", sm: "inherit" },
            }}>
                Enhancements
            </Typography>
            {
                enhancements.length > 0 ? enhancements.map((enhancement) => (
                        <EnhancementComp key={enhancement.Id} enhancement={enhancement} />
                ))
                 : <p style={{ marginTop: "15px" }}>None</p>
            }
            <Typography color="inherit" align="left" variant="h3" marked="center" style={{ zIndex: 1, fontSize: "2rem" }} sx={{
                marginTop: { xs: "40px", sm: "inherit" },
            }}>
                Bugs
            </Typography>
            {
                bugs.length > 0 ? bugs.map((bug) => (
                        <BugComp key={bug.Id} bug={bug} />
                ))
                 : <p style={{ marginTop: "15px" }}>None</p>
            }
          </Box>
          <nav className="blog-post-nav">
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <Link to={`/release/${previous.RowKey}`} rel="prev" style={{ textDecoration: "none" }}>
                        <Chip icon={<ChevronLeftIcon />} label={previous.Title} style={{ cursor: "pointer" }} />
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={`/release/${next.RowKey}`} rel="next" style={{ textDecoration: "none" }}>
                        <Chip icon={<ChevronRightIcon />} label={next.Title} style={{ cursor: "pointer", direction: "rtl", paddingRight: "10px" }} className="nextChip" />
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
      </Container>
      
    </Layout>
  )
}

export default ReleaseNotesTemplate

export const pageQuery = graphql`
  query ReleaseNotesById(
    $id: String!
    $previousReleaseNotesId: String
    $nextReleaseNotesId: String
  ) {
    site {
      siteMetadata {
        title
        author {
          name
          summary
        }
        social {
          twitter
        }
      }
    }
    releaseNotes(RowKey: { eq: $id }) {
      id
      Title
      Timestamp
      RowKey
      PartitionKey
      Iteration
      BugIDs
      EnhancementIDs
      Body
    }
    previous: releaseNotes(RowKey: { eq: $previousReleaseNotesId }) {
        Title
        RowKey
    }
    next: releaseNotes(RowKey: { eq: $nextReleaseNotesId }) {
        Title
        RowKey
    }
  }
`
